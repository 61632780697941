import DashboardLayout from "@/pages/layouts/DashboardLayout";

let serviceRoutes = {
  path: '/services',
  component: DashboardLayout,
  redirect: '/services/list',
  children: [
    {
      path: 'list',
      name: 'Service List',
      component: () => import(/* webpackChunkName: "services" */  '@/pages/services/ServiceList'),
      meta: {
        permissionsCodes: ['services/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Service',
      component: () => import(/* webpackChunkName: "services" */  '@/pages/services/ServiceForm'),
      meta: {
        permissionsCodes: ['services/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Service',
      component: () => import(/* webpackChunkName: "services" */  '@/pages/services/ServiceForm'),
      meta: {
        permissionsCodes: ['services/update'],
      }
    }
  ]
}

export default serviceRoutes;
